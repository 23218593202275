import { IReconstructionTransaction } from "../pricing/pricing";
import { IHasDates } from "../shared/utils";


export enum GENERATE_TYPE {
    TEXT_TO_3D = 1,
    IMAGE_TO_3D = 2,
}

export enum TETURE_GENERATE_TYPE {
    EXISTING = 1,
    FOREIGN = 2,
}

export enum MEDIA_TYPE {
    MODEL = 1,
    IMAGE = 2,
}


export interface IReconstruct {
    images?: Array<string>;
    method?: string; // "TRIPOSR"
    remove_background?: boolean;
    text?: string;
    video_url?: string;
    // refine: boolean;
    action_id: RECONSTRUCTION_ACTION;
    source_job_id?: number;
    source_glb_url?: string;
}

export interface IReconstructJobUI extends IReconstructJob {
    _isCurrent: boolean;
    _delayEnter?: number;
}

export interface IReconstructJob extends IHasDates {
    end_time: Date;
    error_log: any;
    glb_url: string;
    id: number;
    is_archived: boolean;
    method: string;
    params: string;
    ply_url: string;
    preview: string;
    start_time: Date;
    status: RECONSTRUCT_JOB_STATUS;
    steps: Array<any>;
    user_id: number;
    viewer_url: string;
    // source_id: number;
    // is_refined: number;
    text: string;
    action_id: RECONSTRUCTION_ACTION;
    credits_used: number;
    source_action_id: RECONSTRUCTION_ACTION;
    source_job_id: number;
    reconstruction_jobs_inputs: Array<IReconstructJobInput>;
}

export enum RECONSTRUCT_JOB_STATUS {
    Completed = 'Completed',
    Failed = 'Failed',
    'In Progress' = 'In Progress',
    Queued = 'Queued'
}

export interface IReconstructJobInput {
    id: number;
    text_input: string;
    job_id: number;
    image_url: string;
    video_url: string;
}

export interface IFilterOptions {
    limit: number;
    offset: number;
    is_desc: boolean;
    order_by: string;
}

export interface IReconstructJobOptions extends IHasDates, IFilterOptions {
    is_archived: boolean;
}

export interface IReconstructJobImageOptions extends IHasDates, IFilterOptions {

}

export interface IAllReconstructionJobs {
    allReconstructionJobs: IAllReconstructionJobsInner;
}

export interface IReconstructionJobQuery {
    reconstruction_jobs: IReconstructJob;
}

export interface IAllReconstructionJobsInner {
    count: number;
    rows: Array<IReconstructJob>;
}

export interface IAllReconstructionImages {
    allReconstructionImages: IAllReconstructionImagesQuery;
}

export interface IAllReconstructionImagesQuery {
    count: number;
    rows: Array<IReconstructImage>;
}

export interface IReconstructJobsQueryData {
    allReconstructionJobs: IAllReconstructionJobs;
}

export interface IReconstructImage extends IHasDates {
    id: number;
    request_id: string;
    url: string;
    user_id: number;
    prompt: string;
}

export interface IReconstructImageUI extends IReconstructImage {
    _delayEnter: number;
}

export interface ISimilarItem {
    name: string;
    product_id: number;
    image_url: string;
    score: number;
    credits: number;
    resource_default: string;
    resource_big: string;
    resource_id: number;
    poly_count: number;
    vert_count: number;
    // source_files: Array<ISourceFile>;
    products_resources_files_details: Array<ProductResourceFilesDetails>;
    products_resources_source_files: Array<ProductResourceSourceFile>;
}

export interface ProductResourceFilesDetails {
    file_name: string;
    file_size: number;
    id: number;
    resource_id: number;
}

export interface ProductResourceSourceFile {
    id: number;
    software_id: number;
}

export interface ISourceFile {
    id: number;
    software_id: number;
}

export interface IReconstructionAction  {
    id: number;
    description: string;
    credits: number;
    estimated_duration: number;

}

export enum RECONSTRUCTION_ACTION {
    RECONSTRUCTION = 1,
    REFINE = 2,
    RE_TEXTURE = 3,
    PURCHASE_SIMILAR = 4,
    PURCHASE_OBJAVERSE = 5,
    RECONSTRUCTION_FROM_TEXT = 6,
}


export interface IAllReconstructionAction {
    data: IAllReconstructionActionData;
}

export interface IAllReconstructionActionData {
    allReconstructionActions: Array<IReconstructionAction>;
}

export interface IallReconstructionTransactions {
    data: IallReconstructionTransactionsData;
}

export interface IallReconstructionTransactionsData {
    count: number;
    rows: Array<IReconstructionTransaction>;
}

export interface IAiTuxtureDialogData {
    glbURL: string;
}

export enum CREATIONS_LIST_TYPE {
    INFO = 1,
    THUMBS = 2,
    HORIZON_THUMBS = 3,
    DESKTOP_INFO_HORIZON_THUMBS_MOBILE = 4,
}

export enum IMAGE_LIST_TYPE {
    VERTICAL = 1,
    HORIZONTAL = 2,
    HORIZONTAL_MOBILE = 3,
}