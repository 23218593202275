<app-balls></app-balls>
<div class="wrap component-wrap">
  <section class="col head pos-rel">
    <div class="head-bg pos-abs"></div>
    <h1 class="text-center">
      <p>Shape Your Imagination</p>
      <p>
        with <br class="hidden-desktop" /><span class="italic"
          >AI Powered 3D</span
        >
      </p>
    </h1>
    <div class="text-center">
      Turn words into worlds with AI modeling and an elite marketplace of ready
      to use 3d models
    </div>
    <div class="text-center">
      <a mat-raised-button class="btn btn-a big cta" [routerLink]="ctaLink">
        start For Free
      </a>
    </div>
  </section>
  <section class="mobile-col">
    <div class="disp-flex mobile-col">
      <div class="disp-flex col justify-content-center">
        <h2 class="ttl-xl bold space-bottom-lg italic">Text to 3D</h2>
        <div class="space-bottom-3xl space-bottom-2xl-mobile ttl-xs bold">
          <div>From Text to Detailed 3D Models</div>
          <div>Effortlessly turn descriptions into precise 3D creations.</div>
        </div>
        <div>
          <div class="dummy-input align-items-center disp-flex">
            <!-- <span class="ttl-xxs">Brown comfy single couch</span> -->
            <span class="ttl-xxs">a green velvet chair with wooden legs</span>
            <button
              title="Generate"
              type="button"
              mat-raised-button
              class="btn btn-a"
            >
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="disp-flex mobile-col">
      <div class="disp-flex col justify-content-center pos-rel viewer-wrap">
        <app-viewer
          [src]="
            'https://v.hexa3d.io/index.html?load=%2Fviews%2Fproduction%2Freconstruction%2F57e0d980b1fb41b7b02b4e73bf93235d%2F57e0d980b1fb41b7b02b4e73bf93235d.glb&autorotate=true&auto-adjust=1&tv=155'
          "
        ></app-viewer>
      </div>
    </div>
  </section>
  <section class="mobile-col-reverse">
    <div class="disp-flex">
      <div class="disp-flex col justify-content-center pos-rel viewer-wrap">
        <app-viewer
          [src]="
            'https://v.hexa3d.io/index.html?load=/views/production/reconstruction/6f2fe26648fe4ddda5d2fbe991cf7d08/6f2fe26648fe4ddda5d2fbe991cf7d08.glb&autorotate=true&auto-adjust=1&tv=164'
          "
        ></app-viewer>
      </div>
    </div>
    <div class="disp-flex">
      <div class="disp-flex col justify-content-center">
        <h2 class="ttl-xl bold space-bottom-lg italic">Image to 3D</h2>
        <div class="space-bottom-3xl space-bottom-2xl-mobile ttl-xs bold">
          <div>Seamless Image to 3D Conversion – Transform your</div>
          <div>images into realistic 3D models instantly</div>
        </div>
        <div>
          <div class="dummy-image pos-rel">
            <img
              src="https://himg-cdn.com/reconstruction/images/65c7b2ba-de82-48af-b5b7-8cdd578a7d2b/3.png?w=164&h=164"
              width="164"
              height="164"
              alt="mouse"
            />
            <button
              title="Generate"
              type="button"
              mat-raised-button
              class="btn btn-a"
            >
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="mobile-col">
    <div class="disp-flex">
      <div class="disp-flex col justify-content-center">
        <h2 class="ttl-xl bold space-bottom-lg italic">AI-Powered Texturing</h2>
        <div class="space-bottom-3xl space-bottom-2xl-mobile ttl-xs bold">
          <div>
            Advanced AI Texturing – Automatically apply new textures to your 3D
            models with our AI algorithms.
          </div>
        </div>
        <div>
          <div class="dummy-texture align-items-center disp-flex col">
            <div class="disp-grid inner space-bottom-lg">
              <div class="upload disp-flex pc-center-space-between text-center">
                <img src="/assets/images/cube.svg" alt="cube" />
                <div class="capitalize-fl txt">upload model</div>
              </div>
              <div class="plus pc-center-space-between">+</div>
              <div class="prompt">
                <!-- smooth, matte black finish with subtle carbon fiber detailing
                along the edges... -->
                <!-- Womens Biege Nellie Cut Out Slide Flat Sandals -->
                Mid-century modern bedside table with a single drawer
              </div>
            </div>
            <div class="w-100">
              <button
                title="Generate"
                type="button"
                mat-raised-button
                class="btn btn-a w-100"
              >
                <img
                  src="/assets/images/ai-texture.svg"
                  alt="text"
                  class="va-middle"
                />
                Texture 3D model
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="disp-flex">
      <div class="disp-flex col justify-content-center pos-rel viewer-wrap">
        <app-viewer
          [src]="
            'https://v.hexa3d.io/index.html?load=%2Fviews%2Fproduction%2Freconstruction%2F7f4f0de0f3ee40b98c7edc159fef6d37%2F7f4f0de0f3ee40b98c7edc159fef6d37.glb&autorotate=true&auto-adjust=1&tv=155'
          "
        ></app-viewer>
      </div>
    </div>
  </section>
  <section class="col">
    <div class="text-center">
      <h3 class="ttl-xl bold space-bottom-lg">
        Our Curated <span class="italic">Marketplace</span>
      </h3>
      <div class="ttl-xs bold xw-800 center-me disp-flex">
        Browse through a selection of professionally hand crafted 3D models that
        complement your creation. With Substance Painter source files available
        for in-depth customization.
      </div>
    </div>
    <div class="similar-teaser">
      <div>
        <h3 class="ttl-hero">
          Our Technology, <span class="italic">Your Imagination</span>
        </h3>
        <div class="text-center similar-desc">
          Unbox your creativity with HexaGen. Sign up now and start shaping your
          world
        </div>
      </div>
      <div class="text-center">
        <a mat-raised-button class="btn btn-b big" [routerLink]="ctaLink">
          start For Free
        </a>
      </div>
    </div>
  </section>
</div>
<div class="fw">
  <div class="wrap">
    <app-footer></app-footer>
  </div>
</div>
<mat-divider class="w-100"></mat-divider>
<div class="fw">
  <div class="wrap">
    <app-rights></app-rights>
  </div>
</div>
