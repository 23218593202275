import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RenderService {
  private _text: string;
  private _createdImages: Array<string>;
  private _createdImagesIndex: number;
  constructor() { }

  get text() {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
  }

  get createdImages() {
    return this._createdImages;
  }

  set createdImages(value: Array<string>) {
    this._createdImages = value;
  }

  get createdImagesIndex() {
    return this._createdImagesIndex;
  }

  set createdImagesIndex(value: number) {
    this._createdImagesIndex = value;
  }
}
