import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { UtilsService } from '../../shared/utils.service';
import { ViewerService } from '../../shared/viewer.service';
import { themeColor } from '../../shared/constants';
import { THREE_LATEST_VERSION } from 'asset-adjustments';
import { CommonModule } from '@angular/common';
import { GenerateService } from '../../generate/generate.service';
import { PixelsService } from '../../shared/pixels.service';
import { RenderService } from '../render.service';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {
  IPhysicalPlaneOptions,
  IPostMessageOrigin,
  IShadowPlaneOptions,
  TextureMimeType,
} from 'hexa-viewer-communicator';
import { ImageCardComponent } from '../../shared/image-card/image-card.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OrSeparatorComponent } from '../../shared/or-separator/or-separator.component';
import { MatSliderModule } from '@angular/material/slider';

@Component({
  selector: 'app-render',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ImageCardComponent,
    MatProgressBarModule,
    OrSeparatorComponent,
    MatSliderModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './render.component.html',
  styleUrl: './render.component.scss',
})
export class RenderComponent implements OnInit {
  public viewerServer: string;
  public viewerService: ViewerService;
  public themeColor = themeColor;
  public hasFile: boolean;
  public hasImage: boolean;
  public loadingFiles: boolean;
  public envImage: string;
  public minShadowHeight: number;
  public maxShadowHeight: number;
  public stepShadowHeight: number;
  public shadowPlane: IShadowPlaneOptions;
  public parentStyle: any;
  public textStyle: any;
  public iconStyle: any;
  public envIntensity: number;
  public expIntensity: number;
  public fov: number;
  public skybox: boolean;
  public THREE_LATEST_VERSION = THREE_LATEST_VERSION;
  private _lastImg: string;
  @ViewChild('hexaViewer') hexaViewer: ElementRef;
  constructor(
    private utils: UtilsService,
    public renderService: RenderService,
    private generateService: GenerateService,
    private pixels: PixelsService
  ) {
    this.viewerService = new ViewerService(this.utils);
    this.viewerServer = environment.viewerServer;
    this.shadowPlane = {
      physicalOptions: {} as IPhysicalPlaneOptions,
    } as IShadowPlaneOptions;
    this.parentStyle = {};
    this.textStyle = {};
    this.iconStyle = {};
    this.minShadowHeight = 0;
    this.maxShadowHeight = 1;
    this.stepShadowHeight = 0.1;
    this.envIntensity = 1;
    this.expIntensity = 1;
    this.fov = 35;
    this.skybox = false;
  }

  ngOnInit() {
    this.readyForFile();
  }

  async readyForFile() {
    await this.viewerService.injectScript();
    if (!this.hexaViewer?.nativeElement)
      this.hexaViewer = new ElementRef(
        document.getElementById('ai-texture-prompt-viewer')
      );
    this.hexaViewer.nativeElement.setAttribute('server', this.viewerServer);
    this.hexaViewer.nativeElement.setAttribute('tv', THREE_LATEST_VERSION);
    this.hexaViewer.nativeElement.setAttribute('theme-color', this.themeColor);
    this.viewerService.defineVC(this.hexaViewer.nativeElement);
    await this.viewerService.vc.onModelLoaded();
    this.toggleAllAnumation(false);
    this.hasFile = true;
    this.loadingFiles = false;
  }

  async onFilesChange(event: any) {
    if (!event.srcElement.files.length) return;
    this.loadingFiles = true;
    this.viewerService.vc.displayFiles(event.srcElement.files);
    this.syncScene();
  }

  private async syncScene() {
    const bb = await this.viewerService.vc.getBoundingBox();
    this.minShadowHeight = 0;
    this.maxShadowHeight = bb.y;
    this.stepShadowHeight = bb.y / 20;
    const scene = await this.viewerService.vc.broadcastSceneSummary();
    this.shadowPlane.active = scene.plane.state;
    this.shadowPlane.color = scene.plane.options.color;
    this.shadowPlane.opacity = scene.plane.options.opacity;
    this.shadowPlane.physical = scene.plane.options.physical;
    this.shadowPlane.physicalOptions = scene.plane.options.physicalOptions;
    this.shadowPlane.reflector = scene.plane.options.reflector;
    this.shadowPlane.side = scene.plane.options.side;
  }

  async onImageFilesChange(event: any) {
    if (!event.srcElement.files.length) return;
    this.envImage = await this.utils.getBase64FromFile(
      event.srcElement.files[0]
    );
    this.render(this.envImage);
  }

  async generateImages() {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_2d',
      sub_click_type: 'generate_fro_render',
    });
    this.renderService.createdImages = (
      await this.generateService.reconstructImage(this.renderService.text)
    ).map((r) => r.url);
    this.renderService.createdImagesIndex = 0;
    if (
      this.renderService.createdImages &&
      this.renderService.createdImages[this.renderService.createdImagesIndex]
    )
      this.render(
        this.renderService.createdImages[this.renderService.createdImagesIndex]
      );
  }

  setIframeStyle(width: number, height: number) {
    if (this.hexaViewer) {
      this.viewerService.style['width'] = `${width}px`;
      this.viewerService.style['height'] = `${height}px`;
      const rect =
        this.hexaViewer.nativeElement.parentElement.parentElement.parentElement.getBoundingClientRect();
      const scale = Math.min(rect.width / width, rect.height / height);
      if (scale > 1) {
        this.parentStyle = {};
        this.textStyle = {};
        this.iconStyle = {};
      } else {
        this.parentStyle['transform'] = `scale(${scale})`;
        this.parentStyle['transform-origin'] = `top left`;
        this.textStyle['transform'] = `scale(${1 / scale})`;
        this.textStyle['transform-origin'] = `center top`;
        this.iconStyle['transform'] = `scale(${1 / scale})`;
        this.iconStyle['transform-origin'] = `center bottom`;
      }
    }
  }

  async render(imgUrl: string) {
    this._lastImg = imgUrl;
    const img = await this.utils.preloadImage(imgUrl);
    this.setIframeStyle(img.naturalWidth, img.naturalHeight);
    const base = imgUrl;
    this.viewerService.vc.applyHDRI({
      src: base,
      intensity: this.envIntensity,
      format: await this.utils.isHDR(base) ? 'ultra-hdr' : 'jpg',
      skybox: this.skybox,
    } as any)
    this.viewerService.vc.toggleHideBottom(this.skybox);
    // const md = await this.viewerService.vc.getMeshesData();
    // Object.keys(md).forEach((name: string) => {
    //   this.viewerService.vc.applyTexture({
    //     mapType: 'envMap',
    //     materialName: md[name].materialName,
    //     mimeType: TextureMimeType.IMAGE,
    //     src: base,
    //     intensity: this.envIntensity,
    //   });
    // });
    this.viewerService.style['background-image'] = `url('${base}')`;
    // await this.viewerService.vc.setLightsByJson({});
    this.viewerService.vc.sendToViewer({
      action: 'toggleNoDistanceLimit',
      to: IPostMessageOrigin.WORKER,
      value: true,
    });
  }

  async toggleAllAnumation(state: boolean) {
    const ma = await this.viewerService.vc.getMeshAnimations();
    Object.keys(ma).forEach((name: string) => {
      ma[name].active = state;
    });
    this.viewerService.vc.updateMeshAnimations(ma);
  }

  async downloadImage() {
    const parentStyle = this.utils.deepCopyByValue(this.parentStyle);
    this.parentStyle = { opacity: '0.01' };
    await this.utils.setTimeout(500);
    await this.utils.setTimeout();
    const base =
      this.envImage ||
      this.renderService.createdImages[this.renderService.createdImagesIndex];
    const ss = await this.viewerService.vc.getCurrentScreenshot();
    this.parentStyle = parentStyle;
    this.utils.multipleDownloads(
      [await this.utils.merge2Images(base, ss)],
      'render.png'
    );
  }

  isRenderDisable() {
    return !!!this.renderService.text || !!this.envImage;
  }

  async select(index: number) {
    this.renderService.createdImagesIndex = index;
    if (
      this.renderService.createdImages &&
      this.renderService.createdImages[this.renderService.createdImagesIndex]
    )
      this.render(
        this.renderService.createdImages[this.renderService.createdImagesIndex]
      );
  }

  onShadowHeightEvent(e: any) {
    this.onShadowHeight(e.target.value);
  }

  onShadowHeight(value: number) {
    this.shadowPlane.physicalOptions.height = value;
    this.applySHadow();
  }

  applySHadow() {
    this.viewerService.vc.applyShadowPlane(this.shadowPlane);
  }

  setShadowType(type: number) {
    switch (type) {
      case 1: {
        this.shadowPlane.physical = true;
        this.shadowPlane.reflector = false;
        this.shadowPlane.color = '#000000';
        break;
      }
      case 2: {
        this.shadowPlane.physical = false;
        this.shadowPlane.reflector = true;
        this.shadowPlane.color = '#dddddd';
        break;
      }
    }
    this.applySHadow();
  }

  onHdrIntensityEvent(e: any) {
    this.onHdrIntensity(e.target.value);
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }

  onHdrIntensity(value: number) {
    this.envIntensity = value;
    const img =
      this.envImage ||
      (this.renderService.createdImages
        ? this.renderService.createdImages[
        this.renderService.createdImagesIndex
        ]
        : null);
    if (img) this.render(img);
  }

  onExpEvent(e: any) {
    this.onExp(e.target.value);
  }

  onExp(value: number) {
    this.expIntensity = value;
    this.viewerService.vc.sendToViewer({
      action: 'setExposure',
      to: IPostMessageOrigin.WORKER,
      value: this.expIntensity,
    });
  }

  onFOVEvent(e: any) {
    this.onFOV(e.target.value);
  }

  onFOV(value: number) {
    this.fov = value;
    this.viewerService.vc.sendToViewer({
      action: 'setFOV',
      to: IPostMessageOrigin.WORKER,
      value: this.fov,
    });
  }

  setSkybox(state: boolean) {
    this.skybox = state;
    this.rerender();
  }

  rerender() {
    if (this._lastImg)
      this.render(this._lastImg);
  }
}
