<div class="wrap view-max-width disp-flex row">
  <div class="left">
    @if (renderService.createdImages) {
    <div class="cards-wrap space-bottom-md">
      <button type="button" mat-raised-button class="btn btn-ghost btn-img"
        (click)="renderService.createdImages = null">
        <img src="/assets/images/close.svg" alt="close" />
      </button>
      @for (img of renderService.createdImages; track $index) {
      <app-image-card [ngClass]="{
          current: renderService.createdImagesIndex === $index
        }" class="pointer disp-block" (click)="select($index)" [src]="img"></app-image-card>
      }
    </div>
    } @else { @if (!envImage) {
    <div class="space-bottom-md">
      <div class="disp-flex">
        <span class="txt-xxs bold capitalize-fl space-bottom-sm">insert prompt</span>
      </div>
      <mat-form-field class="w-100 ta-wrap no-subscript">
        <textarea rows="6" [(ngModel)]="renderService.text"
          placeholder="dragon wings and unicorn head hybrid creature, highly detailed..." matInput></textarea>
      </mat-form-field>
    </div>
    <div>
      <button (click)="generateImages()" type="button" mat-raised-button class="btn btn-c w-100"
        [disabled]="isRenderDisable()">
        <img src="/assets/images/picture.svg" alt="picture" class="va-middle"
          [ngClass]="{ 'filter-disabled': isRenderDisable() }" />
        Render
      </button>
    </div>
    } @if (!envImage && !renderService.createdImages) {
    <app-or-separator>or</app-or-separator>
    } @if (!renderService.createdImages) {
    <div class="image-drop pos-rel space-bottom-lg">
      @if (envImage) {
      <button type="button" mat-raised-button class="btn btn-ghost btn-img pos-abs" (click)="envImage = null">
        <img src="/assets/images/close.svg" alt="close" />
      </button>
      <img [src]="envImage" alt="environment" class="environment space-bottom-lg" />
      } @else {
      <input placeholder="drop image here" type="file" class="poiner" accept="image/*" miltiple="false"
        (change)="onImageFilesChange($event)" />
      <div class="dummy disp-flex pos-abs col text-center">
        <img src="/assets/images/picture.svg" alt="cube" />
        <div>
          <div class="desc1 txt-xs capitalize-fl space-bottom-sm">
            upload environment image
          </div>
          <div class="desc2 txt-xxs">png, jpeg, webp</div>
        </div>
      </div>
      }
    </div>
    } } @if (hasFile) {
    <div class="controls disp-flex col">
      <!-- <div>
        <div class="buttons disp-flex">
          <div class="button-wrap">
            <button
              (click)="setShadowType(1)"
              [ngClass]="{ active: shadowPlane.physical }"
              type="button"
              mat-raised-button
              class="btn btn-g large small-text"
            >
              physical
            </button>
          </div>
          <div class="button-wrap">
            <button
              (click)="setShadowType(2)"
              [ngClass]="{ active: shadowPlane.reflector }"
              type="button"
              mat-raised-button
              class="btn btn-g large small-text"
            >
              reflection
            </button>
          </div>
        </div>
      </div> -->
      <!-- <div>
        shadow intensity
        <mat-slider [min]="0" [max]="1" [step]="0.05">
          <input
            (input)="applySHadow()"
            (valueChange)="applySHadow()"
            placeholder="shadow intensity"
            [value]="shadowPlane.opacity"
            matSliderThumb
          />
        </mat-slider>
      </div> -->
      @if (shadowPlane.physical) {
      <div>
        shadow height
        <mat-slider [max]="maxShadowHeight" [min]="minShadowHeight" [step]="stepShadowHeight" color="accent">
          <input placeholder="shadow height" (input)="onShadowHeightEvent($event)"
            (valueChange)="onShadowHeight($event)" [value]="shadowPlane.physicalOptions.height" matSliderThumb />
        </mat-slider>
      </div>
      <!-- <div>
        shadow blur
        <mat-slider max="1" min="0" step="0.05">
          <input
            placeholder="shadow blur"
            (input)="applySHadow()"
            (valueChange)="applySHadow()"
            [value]="shadowPlane.physicalOptions.blur"
            matSliderThumb
          />
        </mat-slider>
      </div> -->
      <div>
        HDRI intensity
        <mat-slider [max]="3" [min]="0" [step]=".1" color="accent">
          <input placeholder="HDRI intensity" (input)="onHdrIntensityEvent($event)"
            (valueChange)="onHdrIntensity($event)" [value]="envIntensity" matSliderThumb />
        </mat-slider>
      </div>
      <!-- <div>
        exposure
        <mat-slider
          [max]="2"
          [min]="0"
          [step]=".1"
        >
          <input
            placeholder="exposure"
            (input)="onExpEvent($event)"
            (valueChange)="onExp($event)"
            [value]="expIntensity"
            matSliderThumb
          />
        </mat-slider>
      </div> -->
      }
      <div>
        field of view
        <mat-slider [max]="90" [min]="5" [step]="1" color="accent">
          <input placeholder="field of view" (input)="onFOVEvent($event)" (valueChange)="onFOV($event)" [value]="fov"
            matSliderThumb />
        </mat-slider>
      </div>

      <div class="space-bottom-sm disp-flex pc-space-between align-items-center">
        <div>
          deployment
        </div>
        <div>
          <div class="buttons disp-flex">
            <div class="button-wrap">
              <button (click)="setSkybox(false)" [ngClass]="{ active: !skybox }" type="button" mat-raised-button
                class="btn btn-g small-text">
                Background
              </button>
            </div>
            <div class="button-wrap">
              <button [ngClass]="{ active: skybox }" (click)="setSkybox(true)" type="button" mat-raised-button
                class="btn btn-g small-text">
                Skybox
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    }
    <button (click)="downloadImage()" type="button" mat-raised-button class="btn btn-a large w-100"
      [disabled]="!hasFile">
      <img src="/assets/images/download.svg" alt="download" class="va-middle"
        [ngClass]="{ 'filter-disabled': !hasFile }" />
      Download
    </button>
  </div>

  <div class="right flex-def" [ngClass]="{ noof: hasFile || envImage }">
    <div [ngStyle]="parentStyle">
      <div class="pos-rel viewer-wrap center-me" [ngClass]="{ 'has-file': hasFile }" [ngStyle]="viewerService.style">
        @if (!hasFile && loadingFiles) {
        <mat-progress-bar class="pos-abs" mode="indeterminate"></mat-progress-bar>
        }
        <input placeholder="drop model here" type="file" class="poiner" accept="*/*" miltiple="true"
          (change)="onFilesChange($event)" />
        <div class="dummy disp-flex pos-abs col text-center">
          <img src="/assets/images/cube.svg" alt="cube" [ngStyle]="iconStyle" />
          <div [ngStyle]="textStyle">
            <div class="desc1 txt-xs capitalize-fl space-bottom-sm">
              upload model
            </div>
            <div class="desc2 txt-xxs">
              glb, glTF, fbx, obj, dae, stl and more
            </div>
          </div>
        </div>
        @if (hasFile) {
        <button type="button" mat-raised-button class="btn btn-ghost btn-img pos-abs" (click)="hasFile = false">
          <img src="/assets/images/close.svg" alt="close" />
        </button>
        }
        <hexa-viewer id="ai-texture-prompt-viewer" #hexaViewer class="w-100" embed-loader="true" autorotate="true"
          auto-adjust="true"></hexa-viewer>
      </div>
    </div>
  </div>
</div>