import { Component, OnDestroy, OnInit } from '@angular/core';
import { BallsComponent } from '../shared/balls/balls.component';
import { MatButtonModule } from '@angular/material/button';
import { ViewerComponent } from '../shared/viewer/viewer.component';
import { FooterComponent } from '../footer/footer.component';
import { RightsComponent } from '../rights/rights.component';
import { MatDividerModule } from '@angular/material/divider';
import { RouterLink } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MetaOptions } from '../shared/seo/seo';
import { PlatformName } from '../shared/constants';
import { environment } from '../../environments/environment';
import { SeoService } from '../shared/seo/seo.service';

@Component({
  selector: 'app-site',
  standalone: true,
  imports: [BallsComponent, MatButtonModule, ViewerComponent, FooterComponent, RightsComponent, MatDividerModule, RouterLink],
  templateUrl: './site.component.html',
  styleUrl: './site.component.scss'
})
export class SiteComponent implements OnDestroy, OnInit {
  public ctaLink: string;
  constructor(
    private auth: AuthService,
    private seo: SeoService
  ) {
    document.body.classList.add('gradient');
    this.ctaLink = '/login';
  }

  async ngOnInit() {
    let mOptions = new MetaOptions();
    mOptions.title = `${PlatformName} | Home Page`;
    mOptions.keywords = `${PlatformName}, Free, 3D Models, AI`;
    mOptions.description = `${mOptions.description} On this page you can read about the platform.`;
    mOptions.canonical = `${environment.domain}`;
    this.seo.setMetaDate(mOptions);
    if (await this.auth.isloggedIn()) {
      this.ctaLink = '/generate';
    }
  }

  ngOnDestroy() {
    document.body.classList.remove('gradient');
  }
}
