import { Injectable } from '@angular/core';
import { EndpointsService } from './endpoints.service';
import { HttpClient } from '@angular/common/http';
import { CacheService } from '../shared/cache.service';
import { StorageService } from 'ng-storage-service';
import { BroadcasterService } from 'ng-broadcaster';
import { GlobalsService } from '../shared/globals.service';
import { EndPoints } from './endpoints';
import { CORS_TOKEN } from '../shared/constants';
import { CustomRequestOptions } from './custom-request';
import { Observable, catchError, finalize, of, tap, throwError } from 'rxjs';
import { ScreenNotificationType } from '../shared/enums';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  public websocketEndpoint = this.endpoints.getEndpointDomain(EndPoints.WEBSOCKETS);
  public notificationsWebsocketEndpoint = this.endpoints.getEndpointDomain(EndPoints.NOTIF_WEBSOCKETS);
  constructor(
    private endpoints: EndpointsService,
    private _httpClient: HttpClient,
    private _cache: CacheService,
    private storage: StorageService,
    private broadcaster: BroadcasterService,
    private globals: GlobalsService
  ) {
    this.endpoints = new EndpointsService();
  }

  private logOut() {
    // this.storage.remove('user');
    // this.storage.remove('token');
    // this.broadcaster.broadcast('onLogout');
    this.broadcaster.broadcast('execLogout');
  };

  public auth(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/session', method, payload, query, options);
  }

  public userProfile(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/user', method, payload, query, options);
  }

  public retailer(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer', method, payload, query, options);
  }

  public product(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product', method, payload, query, options);
  }

  public notificationTypes(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/notification/type', method, payload, query, options);
  }

  public passwordRecovery(query: string = '') {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/session', 'get', {}, query);
  }

  public rolesConfig(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/role/permission', method, payload, query, options);
  }

  public enums(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/enum', method, payload, query, options);
  }

  public productSummary(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/summary', method, payload, query, options);
  }

  public productScrape(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/scrape', method, payload, query, options);
  }

  public feedback(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/resource/feedback', method, payload, query, options);
  }

  public feedbackComment(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/resource/feedback/comment', method, payload, query, options);
  }

  public productResourceUpload(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/resource/upload', method, payload, query, options);
  }

  public uploadToClientStorage(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/resource/upload-to-client-storage', method, payload, query, options);
  }

  public uploadSourceFileToClientStorage(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/resource/upload-source-file-to-client-storage', method, payload, query, options);
  }

  public imageBase64Convert(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CAROUSEL) +
      '/image', method, payload, query, options);
  }

  public retailerDomains(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/domain', method, payload, query, options);
  }

  public retailerSettings(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/settings', method, payload, query, options);
  }

  public retailerConfig(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/config', method, payload, query, options);
  }

  public retailerScraper(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/scraper/config', method, payload, query, options);
  }

  public retailerSpec(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/spec', method, payload, query, options);
  }

  public productDuplicate(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/duplicate', method, payload, query, options);
  }

  public resourcesDuplicate(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/resource/duplicate', method, payload, query, options);
  }

  public pushDetails(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/user/push/details', method, payload, query, options);
  }

  public broadcastNotification(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/notification/broadcast', method, payload, query, options);
  }

  public retailerResourceType(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/resource/type', method, payload, query, options);
  }

  public sourceFileType(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/source/file/type', method, payload, query, options);
  }

  public retailerMapping(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/mapping', method, payload, query, options);
  }

  public retailerToken(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/user/token', method, payload, query, options);
  }

  public gifVideoConvert(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/gif/convert', method, payload, query, options);
  }

  public retailerBudget(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/budget', method, payload, query, options);
  }

  public retailerCategories(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/categories', method, payload, query, options);
  }

  public productRender(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/render', method, payload, query, options);
  }

  public retailerRenderSetting(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/render/setting', method, payload, query, options);
  }

  public retailerRenderAngle(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/render/angle', method, payload, query, options);
  }

  public retailerCategoriesRenderAngle(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/categories/render/angle', method, payload, query, options);
  }

  public retailerPolygonSpec(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/polygon/spec', method, payload, query, options);
  }

  public retailerSubcategory(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/subcategory', method, payload, query, options);
  }

  public retailerSubcategorySpec(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/subcategory/spec', method, payload, query, options);
  }

  public retailerContract(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/contract', method, payload, query, options);
  }

  public retailerBatch(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/batch', method, payload, query, options);
  }

  public retailerBatchSpec(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/batch/spec', method, payload, query, options);
  }

  public addBatchesToProduct(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/batch', method, payload, query, options);
  }
  public productReport(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.ANALYTICS) +
      '/product/report', method, payload, query, options);
  }

  public retailerAgreement(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/agreement', method, payload, query, options);
  }

  public productFeedbackFix(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/resource/feedback/fix', method, payload, query, options);
  }

  public clearAllCarouselsCache(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/carousel', method, payload, query, options);
  }

  public renderBilling(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/product/render/billing', method, payload, query, options);
  }

  public viewerParams(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/viewer/params', method, payload, query, options);
  }

  public afterResource(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.UPLOAD) +
      '/resource', method, payload, query, options);
  }

  public meshConvention(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/mesh/convention', method, payload, query, options);
  }

  public accountManager(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/account/manager', method, payload, query, options);
  }

  public retailerInvoice(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.CMS) +
      '/retailer/invoice', method, payload, query, options);
  }

  public approveRender(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain('cms') +
      '/product/render', method, payload, query, options);
  }
  public afterCdn(method: string = 'get', payload: Object = {}, query: string = '', options?: CustomRequestOptions): Observable<any>{
    let payloadAny = payload as any;
    if (payloadAny && !payloadAny.bucket) {
      payloadAny.bucket = 'cdn.hexa3d.io';
    }
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.UPLOAD) +
      '/cdn', method, payload, query, options);
  }

  public downloadResource(resourceId: number, type?: Array<number>, imagesType?: Array<string>) {
    const url = this.endpoints.getEndpointDomain(EndPoints.DOWNLOAD) + '/product/download?rid=' + resourceId;
    this.downloadResourceHelper(url, type, imagesType);
  }

  // public downloadResources(resourceIds: number[], type?: Array<number>, imagesType?: Array<string>) {
  //   const url = this.endpoints.getEndpointDomain(EndPoints.DOWNLOAD) + '/product/download?rids=' + resourceIds.join(',');
  //   this.downloadResourceHelper(url, type, imagesType);
  // }

  private downloadResourceHelper(url: string, type?: Array<number>, imagesType?: Array<string>): void {
      if (type && type.length && type.toString()) {
          url += '&target_resource_type=' + type.toString();
      }
      if (imagesType && imagesType.length && imagesType.toString()) {
          url += '&target_images_formats=' + imagesType.toString();
      }
      let token = '?';
      if (url.indexOf('?') > -1) {
          token = '&';
      }
      token += 'token=' + this.storage.get('token');
      window.open(url + token);
  }

  public batchDownloadResource(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.DOWNLOAD) +
      '/product/download', method, payload, query, options);
  }

  public reconstruct(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.PLAYGROUND) +
      '/reconstruct', method, payload, query, options);
  }

  public reconstructJobs(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.PLAYGROUND) +
      '/job', method, payload, query, options);
  }

  public reconstructImage(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.PLAYGROUND) +
      '/image', method, payload, query, options);
  }

  public similar(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.PLAYGROUND) +
      '/similar', method, payload, query, options);
  }

  public classify(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.PLAYGROUND) +
      '/classify', method, payload, query, options);
  }

  public package(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.PLAYGROUND) +
      '/package', method, payload, query, options);
  }

  public subscription(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.PLAYGROUND) +
      '/paddle/subscription', method, payload, query, options);
  }

  public purchaseResource(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
    return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.PLAYGROUND) +
      '/resource/purchase', method, payload, query, options);
  }


  // public reconstructTexture(method: string = 'post', payload: Object = {}, query: string = '', options?: CustomRequestOptions) {
  //   return this.executeRequest(this.endpoints.getEndpointDomain(EndPoints.PLAYGROUND) +
  //     '/reconstruct/texture', method, payload, query, options);
  // }

  private executeRequest(url: string, method: string = 'get', payload: Object = {}, query: string = '', options: CustomRequestOptions = new CustomRequestOptions()): Observable<any> {
    let key = url + query;
    if (this.endpoints.isLocalhost() && key.indexOf(this.endpoints.getEndpointDomain(EndPoints.CMS)) === 0) {
      if (key.indexOf('?') > -1)
        key += `&cors_override_key=${CORS_TOKEN}`;
      else
        key += `?cors_override_key=${CORS_TOKEN}`;
    }

    method = method.toLowerCase();
    if (!options.ignoreCache && this._cache.has(key)) {
      return of(this._cache.get(key));
    }
    if (options.showLoading) {
      this.globals.increaseNumOfRequestsInProgress();
    }
    options.requestOptions = options.requestOptions || {};
    options.requestOptions.body = payload;
    return this._httpClient.request(method, key, options.requestOptions)
      .pipe(tap(json => {
        if (!options.ignoreCache)
          this._cache.set(key, json);
      }))
      .pipe(catchError((error: any) => {
        this.broadcaster.broadcast('notifyUser', {
          text: error ? error._body : 'unknown error',
          type: ScreenNotificationType.Error,
          action: 'OK'
        });
        if (error.status == 401) {
          this.logOut();
        }
        if (error.status == 403) {
        }
        return throwError(error);
      }))
      .pipe(finalize(() => {
        if (options.showLoading) {
          this.globals.decreaseNumOfRequestsInProgress();
        }
      }));
  }
}
