import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CREATIONS_LIST_TYPE,
  IReconstructJob,
  IReconstructJobUI,
  IReconstructionAction,
  RECONSTRUCTION_ACTION,
  RECONSTRUCT_JOB_STATUS,
} from '../generate';
import { MomentPipe } from '../../shared/moment.pipe';
import { UtilsService } from '../../shared/utils.service';
import { PREVIEW_FALLBACK } from '../../shared/constants';
import { CommonModule } from '@angular/common';
import { SrcSuffixPipe } from '../../shared/src-suffix.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { GenerateService } from '../generate.service';
import { BadgeComponent } from '../../shared/badge/badge.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-creation-card',
  standalone: true,
  imports: [
    MomentPipe,
    CommonModule,
    MatTooltipModule,
    BadgeComponent,
    MatButtonModule,
  ],
  templateUrl: './creation-card.component.html',
  styleUrl: './creation-card.component.scss',
  providers: [SrcSuffixPipe],
})
export class CreationCardComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() item: IReconstructJobUI;
  @Input('type') type: CREATIONS_LIST_TYPE;
  @Input() counter: number;
  @Input() actions: boolean;
  @Output() onSelect: EventEmitter<IReconstructJobUI>;
  @Output() onRetry: EventEmitter<IReconstructJobUI>;
  @Output() onDuplicate: EventEmitter<IReconstructJobUI>;
  @Output() onDeleteMe: EventEmitter<IReconstructJobUI>;
  public init: boolean;
  public tick: number;
  public loaded: boolean;
  private tickInterval: any;
  private _name: string;
  private _preview: string;
  private _subs: Array<Subscription>;
  private _cancelNext: boolean;
  public RECONSTRUCT_JOB_STATUS = RECONSTRUCT_JOB_STATUS;
  public InProgress = RECONSTRUCT_JOB_STATUS['In Progress'];
  public RECONSTRUCTION_ACTION = RECONSTRUCTION_ACTION;
  public CREATIONS_LIST_TYPE = CREATIONS_LIST_TYPE;
  public percentage: number;
  public noPreview: boolean;
  public sourceAction: IReconstructionAction;
  private action: IReconstructionAction;
  private _destroyed: boolean;
  public fadeOut: boolean;
  constructor(
    private utils: UtilsService,
    private srcSuffix: SrcSuffixPipe,
    private broadcaster: BroadcasterService,
    private generateService: GenerateService
  ) {
    this.onSelect = new EventEmitter<IReconstructJobUI>();
    this.onRetry = new EventEmitter<IReconstructJobUI>();
    this.onDuplicate = new EventEmitter<IReconstructJobUI>();
    this.onDeleteMe = new EventEmitter<IReconstructJobUI>();
    this.tick = 0;
    this.tickInterval = setInterval(() => {
      this.tick++;
    }, 60000);
    this._subs = [];
  }

  get name() {
    if (this._name) return this._name;
    this._name =
      (this.item.reconstruction_jobs_inputs[0] &&
        this.item.reconstruction_jobs_inputs[0].text_input) ||
      this.item.text ||
      this.item.preview;
    return this._name;
  }

  async ngOnInit() {
    if (typeof this.item._delayEnter !== 'number') {
      this.init = true;
      this.loaded = true;
    } else setTimeout(() => (this.loaded = true), this.item._delayEnter);
    this._subs.push(
      this.broadcaster
        .on('onWebsocketOpen')
        .subscribe(this._onConnection.bind(this))
    );
    this._subs.push(
      this.broadcaster.on('onAnnouncement').subscribe(this.calcPercentage.bind(this))
    );
    this.sourceAction = await this.generateService.getAction(
      this.item.source_action_id || this.item.action_id
    );
    this.action = await this.generateService.getAction(this.item.action_id);
    this.calcPercentage();
  }

  async ngAfterViewInit() {
    await this.utils.setTimeout();
    this.init = true;
  }

  ngOnChanges() {
    delete this._preview;
    delete this._name;
    this.calcPercentage();
  }

  getPreview(item: IReconstructJob) {
    if (this._preview) return this._preview;
    if (this.utils.isURL(item.preview)) {
      this._preview = this.srcSuffix
        .transform(item.preview, '?w=80&h=80', true)
        .toString();
      setTimeout(() => (this.noPreview = false));
    } else {
      this._preview = PREVIEW_FALLBACK;
      setTimeout(() => (this.noPreview = true));
    }
    // this._preview = this.utils.isURL(item.preview)
    //   ? this.srcSuffix.transform(item.preview, '?w=80&h=80', true).toString()
    //   : PREVIEW_FALLBACK;
    return this._preview;
  }

  select(item: IReconstructJobUI) {
    if (this._cancelNext) {
      this._cancelNext = false;
      return;
    }
    this.onSelect.next(item);
  }

  async _onConnection() {
    if (
      this.item.status !== RECONSTRUCT_JOB_STATUS.Completed &&
      this.item.status !== RECONSTRUCT_JOB_STATUS.Failed
    ) {
      this.item = await this.generateService.updateJob(this.item);
    }
  }

  getMul() {
    return this.type === CREATIONS_LIST_TYPE.INFO ? 1 : 0.3;
  }

  async calcPercentage() {
    await this.utils.setTimeout();
    if (this._destroyed) return;
    if (
      this.action &&
      this.item.status === RECONSTRUCT_JOB_STATUS['In Progress']
    ) {
      setTimeout(this.calcPercentage.bind(this));
      console.log('app-creation-card', this.action);
      this.percentage = this.utils.calcActionPercentage(this.item, this.action);
    }
  }

  retry() {
    this._cancelNext = true;
    this.onRetry.next(this.item);
  }

  duplicate() {
    this._cancelNext = true;
    this.onDuplicate.next(this.item);
  }

  deleteMe() {
    this._cancelNext = true;
    this.fadeOut = true;
    setTimeout(() => {
      this.onDeleteMe.next(this.item);
    }, 500);
  }

  ngOnDestroy() {
    this._destroyed = true;
    clearInterval(this.tickInterval);
    this._subs.forEach((f) => f.unsubscribe());
  }
}
