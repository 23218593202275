import { Component, ElementRef, OnDestroy } from '@angular/core';
import {
  CREATIONS_LIST_TYPE,
  IMAGE_LIST_TYPE,
  IReconstructImage,
  IReconstructJobUI,
  ISimilarItem,
  MEDIA_TYPE,
} from '../generate';
import { GenerateService } from '../generate.service';
import { CreationsComponent } from '../creations/creations.component';
import { ViewCreationComponent } from '../view-creation/view-creation.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { GeneratedImagesComponent } from '../generated-images/generated-images.component';
import { ViewGeneratedImageComponent } from '../view-generated-image/view-generated-image.component';
import { CreationsFilterComponent } from '../creations-filter/creations-filter.component';
import { BodyService } from '../../shared/body.service';
import { SimilarComponent } from '../similar/similar.component';
import { MatDialog } from '@angular/material/dialog';
import { noPaddDialog } from '../../shared/constants';
import { SimilarPreviewComponent } from '../similar-preview/similar-preview.component';

@Component({
  selector: 'app-creations-wrap',
  standalone: true,
  imports: [
    CreationsComponent,
    ViewCreationComponent,
    CommonModule,
    MatButtonModule,
    GeneratedImagesComponent,
    ViewGeneratedImageComponent,
    CreationsFilterComponent,
    SimilarComponent,
  ],
  templateUrl: './creations-wrap.component.html',
  styleUrl: './creations-wrap.component.scss',
})
export class CreationsWrapComponent implements OnDestroy {
  private _state: MEDIA_TYPE;
  public MEDIA_TYPE = MEDIA_TYPE;
  public CREATIONS_LIST_TYPE = CREATIONS_LIST_TYPE;
  public IMAGE_LIST_TYPE = IMAGE_LIST_TYPE;
  public showSimilar: boolean;
  constructor(
    public generateService: GenerateService,
    private body: BodyService,
    private dialog: MatDialog
  ) {
    this.state = MEDIA_TYPE.MODEL;
  }

  get state() {
    return this._state;
  }

  set state(value: MEDIA_TYPE) {
    this._state = value;
  }

  onSelect(item: IReconstructJobUI) {
    this.generateService.creation = item;
    this.generateService.counter++;
    setTimeout(() => {
      const e = document.querySelector('app-creations-wrap app-view-creation');
      if (e) this.body.scrollToElement(new ElementRef(e));
    });
  }

  onImageSelect(item: IReconstructImage) {
    this.generateService.reconstructedImage = item;
  }

  close() {
    this.generateService.creation = null;
    this.generateService.reconstructedImage = null;
  }

  toggleSimilar() {
    this.showSimilar = !this.showSimilar;
  }

  setSimilar(similar: ISimilarItem) {
    document.body.classList.add(noPaddDialog);
    this.dialog.open(SimilarPreviewComponent, {
      data: similar,
    });
    this.dialog.afterAllClosed.subscribe(() => {
      document.body.classList.remove(noPaddDialog);
    });
  }

  ngOnDestroy() {
    this.close();
  }
}
